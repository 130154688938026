<template>
  <div class="storageInventoryDetail">
    <h1>库存成本明细查询</h1>
    <el-form :inline="true">
      <el-form-item label="日期">
        <el-date-picker :clearable="false" v-model="searchParam.date" type="date" style="width: 130px;" />
      </el-form-item>
      <el-form-item label="仓储">
        <el-select v-model="searchParam.storage" filterable style="width: 120px;" clearable>
          <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="煤矿品名">
        <el-select v-model="searchParam.product" filterable style="width: 120px;" clearable>
          <el-option v-for='productItem in show.productList' :key="productItem.id" :label="productItem.value" :value="productItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-text>当前库存余量：</el-text>
        <el-text size="large" :type="data.inventoryQuantity < 0 ? 'danger' : 'success'">{{ data.inventoryQuantity }}</el-text>
        <el-text style="margin-left: 20px;">当前平均单价：</el-text>
        <el-text size="large" :type="data.inventoryProductPrice < 0 ? 'danger' : 'success'">{{ data.inventoryProductPrice }}</el-text>
        <el-text style="margin-left: 20px;">当前平均运费单价：</el-text>
        <el-text size="large" :type="data.inventoryTransportPrice < 0 ? 'danger' : 'success'">{{ data.inventoryTransportPrice }}</el-text>
      </el-col>
      <el-col :span="12">
        <el-table :data="data.inList" border style="height: calc(100vh - 310px);">
          <el-table-column prop="receiveDate" label="入库日期" width="120" show-overflow-tooltip />
          <el-table-column prop="inQuantity" label="入库数量" show-overflow-tooltip />
          <el-table-column prop="afterQuantity" label="累计入库" show-overflow-tooltip />
          <el-table-column label="单价" show-overflow-tooltip>
            <template #default="scope">
              <el-text>{{ math.halfUp(scope.row.productAmount / scope.row.inQuantity, 2) }}</el-text>
            </template>
          </el-table-column>
          <el-table-column label="上游运费单价" width="120" show-overflow-tooltip>
            <template #default="scope">
              <el-text>{{ math.halfUp(scope.row.transportAmount / scope.row.inQuantity, 2) }}</el-text>
            </template>
          </el-table-column>
        </el-table>
        <div style="height: 10px;" />
        <el-pagination v-model:current-page="searchParam.inPageNum" v-model:page-size="searchParam.inPageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.inTotal" @change="commitFindInList()" />
      </el-col>
      <el-col :span="12">
        <el-table :data="data.outList" border style="height: calc(100vh - 310px);">
          <el-table-column prop="sendDate" label="出库日期" width="120" show-overflow-tooltip />
          <el-table-column prop="outQuantity" label="出库数量" show-overflow-tooltip />
          <el-table-column prop="afterQuantity" label="累计出库" show-overflow-tooltip />
          <el-table-column label="单价" show-overflow-tooltip>
            <template #default="scope">
              <el-text>{{ math.halfUp(scope.row.productAmount / scope.row.outQuantity, 2) }}</el-text>
            </template>
          </el-table-column>
          <el-table-column label="上游运费单价" width="120" show-overflow-tooltip>
            <template #default="scope">
              <el-text>{{ math.halfUp(scope.row.transportAmount / scope.row.outQuantity, 2) }}</el-text>
            </template>
          </el-table-column>
        </el-table>
        <div style="height: 10px;" />
        <el-pagination v-model:current-page="searchParam.outPageNum" v-model:page-size="searchParam.outPageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.outTotal"
          @change="commitFindOutList()" />
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()

const data = reactive({
  inTotal: 0,
  inList: [],
  outTotal: 0,
  outList: [],
  inventoryQuantity: null,
  inventoryProductPrice: null,
  inventoryTransportPrice: null,
})

const show = reactive({
  storageList: [],
  productList: [],
})

api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'product' } }).then(res => {
  show.productList = res.dictList
})

const now = new Date()
const yesterday = new Date(now.getTime() - 1 * 24 * 60 * 60 * 1000)

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.inPageNum = 1
  searchParam.inPageSize = 50
  searchParam.outPageNum = 1
  searchParam.outPageSize = 50
  searchParam.date = yesterday
  searchParam.storage = null
  searchParam.product = null
}
resetSearchParam()

const commitFindInList = () => {
  const params = {}
  params.pageNum = searchParam.inPageNum
  params.pageSize = searchParam.inPageSize
  params.storage = searchParam.storage
  params.product = searchParam.product
  params.receiveDateLe = util.parseTime(searchParam.date, '{y}-{m}-{d}')
  api.get('/backend/inventory/inPage', { params: params }).then(res => {
    data.inList = res.list
    data.inTotal = res.total
  })
}

const commitFindOutList = () => {
  const params = {}
  params.pageNum = searchParam.outPageNum
  params.pageSize = searchParam.outPageSize
  params.storage = searchParam.storage
  params.product = searchParam.product
  params.sendDateLe = util.parseTime(searchParam.date, '{y}-{m}-{d}')
  api.get('/backend/inventory/outPage', { params: params }).then(res => {
    data.outList = res.list
    data.outTotal = res.total
  })
}

const commitFindInventoryQuantity = () => {
  const params = {}
  params.storage = searchParam.storage
  params.product = searchParam.product
  params.date = util.parseTime(searchParam.date, '{y}-{m}-{d}')
  api.get('/backend/inventory/info', { params: params }).then(res => {
    data.inventoryQuantity = res.quantity
    data.inventoryProductPrice = res.productPrice
    data.inventoryTransportPrice = res.transportPrice
  })
}

const commitFindList = () => {
  if (!searchParam.date) {
    ElMessage.error('请选择日期')
    return
  }
  if (!searchParam.storage) {
    ElMessage.error('请选择仓储')
    return
  }
  if (!searchParam.product) {
    ElMessage.error('请选择煤矿品名')
    return
  }
  data.inList = []
  data.inTotal = 0
  data.outList = []
  data.outTotal = 0
  data.inventoryQuantity = null
  data.inventoryProductPrice = null
  data.inventoryTransportPrice = null
  commitFindInList()
  commitFindOutList()
  commitFindInventoryQuantity()
}
</script>

<style lang="less"></style>